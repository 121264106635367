/**
 * Why you might ask? Because we are consuming shared libraries (e.g. react) in
 * our app as well as sharing them to the federated modules. This is good as it
 * allows only one copy of React to be downloaded, but that means they are in
 * separate async chunks. As such, we have to split the initial chunk into an
 * async boundary so webpack can properly load all the required chunks,
 * including shared and remotes.
 *
 * For more info, check out this [help FAQ](https://webpack.js.org/concepts/module-federation/#uncaught-error-shared-module-is-not-available-for-eager-consumption).
 */
import('./bootstrap')

export {}
